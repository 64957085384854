/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuality</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222);\">Pro rodiče</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69); text-decoration: underline;\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify pb--80 pr--0 pt--80" style={{"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"rgba(255,255,255,1)"}} name={"6wj1t3jc4f8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left mt--0" content={"<span style=\"font-weight: bold;\">O nás</span>"}>
              </Title>

              <Text className="text-box text-box--left" content={"Představuje se Vám Mateřská škola Litultovice, která je od 1. 7. 2013 nově vzniklý samostatný právní subjekt s názvem Mateřská škola Litultovice, příspěvková organizace. Ředitelkou této organizace je Mgr. Kamila Onderková.\nMateřská škola v Litultovicích se nachází na klidném místě a je vesnickou dvoutřídní školou. V každém poschodí je šatna, herna, jídelna, ložnice, kuchyň pro výdej stravy a sociální zařízení. K mateřské škole patří zahrada s terasou a pískovištěm. Nabízíme kvalitní péči o všechny děti naší školy.&nbsp;<br><br>Celodenní stravování a rozvoz zajišťuje ŠJ Stěbořice. O zdravý rozvoj dětí pečuje 5 pedagogických pracovnic, 2 provozní pracovnice a školní asistentka. MŠ je školou spádovou, navštěvují ji děti z Mladecka, Lhotky u Litultovic, Životských Hor. Filozofií naší MŠ je rozvíjet samostatné a zdravě sebevědomé děti. Pracujeme podle nového rámcového programu předškolního vzdělávání.<br><br>Pořádáme sportovní a kulturní akce pro děti, jezdíme na výlety. Ve vzdělávání dětí upřednostňujeme rozvíjení smyslového vnímání, jako základu veškerého přirozeného poznávání a denně se věnujeme prevenci špatné výslovnosti. Mateřská škola napomáhá dětem k celkovému rozvoji osobnosti. Rozvíjí schopnosti a dovednosti tak, aby doplňovala výchovu v rodině. Veškeré snažení všech zaměstnanců školy směřuje k tomu, aby zde děti byly maximálně šťastné a spokojené. Spolupracujeme s rodiči, městysem Litultovice, se základní školou Hlavnice a se základní školou Litultovice, se speciálně pedagogickým centrem a s pedagogickou psychologickou poradnou.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#fffdc1","paddingBottom":36,"paddingTop":36}} name={"i624jm59o4"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(7, 7, 7);\">Zaměstnanci</span>"}>
              </Title>

              <Text className="text-box" content={"Mgr. Kamila Onderková – ředitelka školy\n<br>Bc. Lucie Kuchaříková – učitelka\n<br>Jana Dostálová – učitelka\n<br>Daniela Dzidová DiS. – učitelka\n<br>Sylvie Holušová DiS. - učitelka\n<br>Alexandra Zemanová - školní asistent\n<br>Martina Kremserová – školnice\n<br>Marie Dörrerová – provozní pracovnice"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(6, 6, 6);\">Stav tříd pro školní rok 2024-2025</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">I.třída</span> děti 2 až 4 leté - zapsaných 24 dětí\n<br><span style=\"font-weight: bold;\">II.třída</span> děti 4 až 7 leté - zapsaných 24 dětí\n<br>Mateřská škola má kapacitu 56 dětí"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}